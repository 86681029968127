<template>
  <a-modal
    :visible="visible"
    title="創建合作社社員帳號"
    :closable="false"
    :maskClosable="false"
  >
    <template #footer>
      <a-button key="back" :disabled="loading" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">創建帳號</a-button>
    </template>

    <a-form
      :model="formState"
      layout="vertical"
      autocomplete="off"
    >
      <a-row :style="{ marginBottom: '20px' }">
        <a-col :span="24">
          <a-alert
            message="注意"
            description="直接創建使用者帳號，需為此使用者設定臨時密碼，並且自動加入至十里方圓合作社作為社員。"
            type="warning"
            show-icon
          />
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="使用者帳號 (電子信箱)"
            name="email"
            :rules="[{ required: true, message: '請輸入使用者帳號' }]"
          >
            <a-input
              v-model:value="formState.email"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="密碼"
            name="password"
            :rules="[{ required: true, message: '請輸入使用者密碼' }]"
          >
            <a-input
              v-model:value="formState.password"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider>社員基本資料</a-divider>

      <a-row :gutter="20">
        <a-col :span="12">
          <a-form-item
            label="姓氏"
            name="last_name"
            :rules="[{ required: true, message: '請輸入姓氏' }]"
          >
            <a-input
              v-model:value="formState.last_name"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            label="名字"
            name="first_name"
            :rules="[{ required: true, message: '請輸入名字' }]"
          >
            <a-input
              v-model:value="formState.first_name"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="身分證字號"
            name="national_id"
            :rules="[{ required: true, message: '請輸入身分證字號' }]"
          >
            <a-input
              v-model:value="formState.national_id"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="20">
        <a-col :span="12">
          <a-form-item
            label="性別"
            name="gender"
            :rules="[{ required: true, message: '請輸入性別' }]"
          >
            <a-select
              v-model:value="formState.gender"
              :disabled="loading"
              :options="genderOptions"
              size="large"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            label="生日"
            name="birthdate"
          >
            <a-date-picker
              v-model:value="formState.birthdate"
              :disabled="loading"
              placeholder="請選擇日期"
              size="large"
              :style="{ minWidth: '100%' }"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="聯絡電話"
            name="phone"
            :rules="[{ required: true, message: '請輸入聯絡電話' }]"
          >
            <a-input
              v-model:value="formState.phone"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="地址"
            name="address"
          >
            <a-input
              v-model:value="formState.address"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider>合作社資料（自動產生）</a-divider>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="合作社名稱"
            name="coop_name"
          >
            <a-input v-model:value="formState.coop_name" readonly size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="合作社代號"
            name="coop_id"
          >
            <a-input v-model:value="formState.coop_id" readonly size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="社員編號"
            name="coop_user_id"
            :rules="[{ required: true, message: '請輸入社員編號' }]"
          >
            <a-input
              v-model:value="formState.coop_user_id"
              :disabled="loading"
              size="large"
              readonly
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, ref, reactive } from 'vue'
import axios from 'axios'
import * as bip39 from 'bip39'
import CryptoJS from 'crypto-js'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import DateUtil from '@/utils/DateUtil'

export default defineComponent({
  props: ['visible'],
  components: {},
  setup() {
    const store = useStore();

    const loading = ref(false);

    /* Form */
    const initialState = {
      jwt: null,

      // user
      email: '',
      password: '',

      // user info
      last_name: '',
      first_name: '',
      national_id: '',
      gender: '',
      birthdate: '',
      phone: '',
      address: '',

      // coop info
      coop_name: store.state.auth.info.coop_info.name,
      coop_id: store.state.auth.info.coop_info.coop_id,
      coop_user_id: '',
    }
    const formState = reactive({ ...initialState });
    function resetForm() {
      loading.value = false;
      Object.assign(formState, initialState);
    }

    return {
      loading,
      
      /* Form */
      formState,
      resetForm,

      /* Options */
      genderOptions: [{
        label: '先生',
        value: 'male'
      }, {
        label: '女士',
        value: 'female'
      }],

      message
    }
  },
  watch: {
    formState: {
      handler(newValue, _) {
        if (newValue.national_id.length === 10 && newValue.phone.length === 10) {
          this.formState.coop_user_id = this._coopUserIdGenerator(newValue.national_id, newValue.phone);
        } else {
          this.formState.coop_user_id = '';
        }
      },
      deep: true
    }
  },
  methods: {
    async handleOk() {
      await this.createUser();
    },
    handleCancel() {
      this.$emit('cancel');
      this.resetForm();
    },

    /* Private functions */
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    _coopUserIdGenerator(national_id, phone) {
      // rule: 身份證字號首字母+身份證字號最後兩位+電話號碼最後4位
      const id = national_id.charAt(0) + national_id.substring(national_id.length - 2, national_id.length) + phone.substring(national_id.length - 4, national_id.length);
      return id
    },
    // NOTE: deprecated
    // _coopUserIdGenerator(coopId) {
    //   // prefix
    //   let prefix = '';
    //   if (coopId === 'tenmiles') prefix = 'A';

    //   // year
    //   const d = new Date();
    //   const year = d.getFullYear().toString().substr(-2, 2);

    //   // random number
    //   const randomValue = Date.now().toString().substr(-6, 6);

    //   // checksum
    //   let checksum = 0;
    //   for (const i in randomValue) {
    //     checksum += parseInt(randomValue[i]);
    //   }
    //   console.log(checksum)
    //   checksum = 9 - parseInt(checksum % 10);
    //   console.log(checksum)

    //   const id = `${prefix}${year}${randomValue}${checksum}`;
    //   return id
    // },
    _checkRequired() {
      let err = '';

      if (this.formState.email == '') {
        err = '請輸入電子信箱';
      }
      else if (!this.validateEmail(this.formState.email)) {
        err = '電子信箱格式有誤';
      }
      else if (this.formState.password == '') {
        err = '請輸入密碼';
      }
      else if (this.formState.last_name == '') {
        err = '請輸入姓氏';
      }
      else if (this.formState.first_name == '') {
        err = '請輸入名字';
      }
      else if (this.formState.national_id == '') {
        err = '請輸入身分證字號';
      }
      else if (this.formState.phone == '') {
        err = '請輸入聯絡電話';
      }
      else if (this.formState.coop_user_id == '') {
        err = '請輸入正確的身分證字號及聯絡電話格式，以產生社員編號';
      }

      return err
    },

    /* Wallet Functions */
    generateMnemonic() {
      const mnemonic = bip39.generateMnemonic(256);
      return mnemonic
    },
    async createWallet(email, mnemonic, uid) {
      await this.$store.dispatch('common/wallet/createWalletWithMnemonic', {
        name: email,
        mnemonic: mnemonic,
        password: uid,
        prefix: this.$store.state.common.env.addrPrefix
      });
    },

    /* Api */
    async createUser() {
      this.loading = true;

      let err = this._checkRequired();
      if (err) {
        this.message.error(err);
        this.loading = false;
        return
      }

      // register auth
      const registerForm = {
        username: this.formState.email,
        email: this.formState.email,
        password: this.formState.password,
        application_roles: [1],
        coop_info: this.$store.state.auth.info.coop_info.id,
        acitved: false
      }
      const registerUrl = process.env.VUE_APP_API + '/auth/local/register';
      const registerResponse = await axios.post(registerUrl, registerForm);
      const registerRes = registerResponse.data; // response: jwt, user
      this.formState.jwt = registerRes.jwt;
      console.log(registerRes)
      
      // update user's info (last_name, first_name, national_id, gender, birthdate, phone, address)
      await DateUtil.delay(1);
      const userInfoId = await this.getUserInfo('user_info');
      await this.updateUserInfo(userInfoId, undefined, {
        last_name: this.formState.last_name,
        first_name: this.formState.first_name,
        national_id: this.formState.national_id,
        gender: this.formState.gender,
        birthdate: this.formState.birthdate,
        phone: this.formState.phone,
        address: this.formState.address
      });

      // update coop_user
      await this.createCoopUser(
        registerRes.user.id,
        this.$store.state.auth.info.coop_info.id,
        this.formState.coop_user_id,
      );

      // create wallet
      await this.$store.dispatch('common/env/init');
      const mnemonic = this.generateMnemonic();
      await this.createWallet(registerForm.email, mnemonic, registerRes.user.id.toString());
      const walletAddress = this.$store.getters['common/wallet/address'];

      // encrypt mnemonic
      const salt = Math.random().toString(36).slice(-10);
      const encryptedMnemonic = CryptoJS.AES.encrypt(
        mnemonic,
        `${process.env.VUE_APP_ENCRYPT_PREFIX}${salt}`
      ).toString();
      await this.updateUserInfo(userInfoId, 'enc_k', salt);

      // save address & mnemonic
      const userWalletId = await this.getUserInfo('wallet');
      console.log('wallet id:', userWalletId);

      // 更新 wallet 三次
      let walletUpdateSuccess = false;
      let walletUpdateTimes = 0;
      while (walletUpdateTimes < 3 && !walletUpdateSuccess) {
        const updateWalletErr = await this.updateWalletInfo(userWalletId, walletAddress, encryptedMnemonic);
        console.log(updateWalletErr)

        if (!updateWalletErr) walletUpdateSuccess = true;
        walletUpdateTimes += 1;
        await DateUtil.delay(1);
      }
      console.log(walletUpdateSuccess)
      console.log(walletUpdateTimes)
      if (!walletUpdateSuccess) {
        this.message.error('創建會員發生錯誤，無法更新社員的錢包地址');
        this.loading = false;
        return
      }

      this.resetForm();
      this.$emit('confirm');
    },
    
    async getUserInfo(type) {
      const url = process.env.VUE_APP_API + '/users/me';
      const config = {
        headers: {
          Authorization: `Bearer ${this.formState.jwt}`
        }
      }
      let response = await axios.get(url, config);
      let res = response.data;
      console.log(res);

      if (type == 'wallet') {
        return res.wallet
      } else if (type == 'user_info') {
        return res.user_info
      } else {
        return res
      }
    },
    async createCoopUser(userId, coopId, coopUserId) {
      const url = process.env.VUE_APP_API + `/coop-users`;
      const config = {
        headers: {
          Authorization: `Bearer ${this.formState.jwt}`
        }
      }
      const data = {
        user: userId,
        coop: coopId,
        coop_user_id: coopUserId
      }
      await axios.post(url, data, config);
    },
    async updateWalletInfo(walletInfoId, walletAddress, mnemonic) {
      let err = null;
      
      const url = process.env.VUE_APP_API + `/wallets/${walletInfoId}`;
      try {
        const params = {
          mnemonic: mnemonic,
          wallet_Address: walletAddress
        }
        const config = {
          headers: {
            Authorization: `Bearer ${this.formState.jwt}`
          }
        }
        await axios.put(url, params, config);
      } catch (error) {
        console.log(error)
        err = error
      }

      return err
    },
    async updateUserInfo(userInfoId, key=undefined, value) {
      let params = {};
      if (key == 'enc_k') {
        params = { enc_k: value }
      } else if (key == 'wallet_address') {
        params = { wallet_Address: value }
      } else if (key === undefined) {
        params = value;
      }

      let url = process.env.VUE_APP_API + `/user-infos/${userInfoId}`;
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${this.formState.jwt}`
          }
        }
        await axios.put(url, params, config);
      } catch (error) {
        console.log(error)
      }
    }
  }
})
</script>