<template>
  <a-modal
    :visible="visible"
    title="加入合作社"
    :closable="false"
    :maskClosable="false"
  >
    <template #footer>
      <a-button key="back" :disabled="loading" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">確認新增</a-button>
    </template>

    <a-form
      :model="formState"
      layout="vertical"
      autocomplete="off"
    >
      <a-row :style="{ marginBottom: '20px' }">
        <a-col :span="24">
          <a-alert
            message="注意"
            description="綁定已創建之使用者帳號，需要填寫加入會員之必要資料。"
            type="warning"
            show-icon
          />
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="使用者帳號 (電子信箱)"
            name="email"
            :rules="[{ required: true, message: '請輸入使用者帳號' }]"
          >
            <a-input v-model:value="formState.email" :disabled="loading" size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider>社員基本資料</a-divider>

      <a-row :gutter="20">
        <a-col :span="12">
          <a-form-item
            label="姓氏"
            name="last_name"
            :rules="[{ required: true, message: '請輸入姓氏' }]"
          >
            <a-input
              v-model:value="formState.last_name"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            label="名字"
            name="first_name"
            :rules="[{ required: true, message: '請輸入名字' }]"
          >
            <a-input
              v-model:value="formState.first_name"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="身分證字號"
            name="national_id"
            :rules="[{ required: true, message: '請輸入身分證字號' }]"
          >
            <a-input
              v-model:value="formState.national_id"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="20">
        <a-col :span="12">
          <a-form-item
            label="性別"
            name="gender"
            :rules="[{ required: true, message: '請輸入性別' }]"
          >
            <a-select
              v-model:value="formState.gender"
              :disabled="loading"
              :options="genderOptions"
              size="large"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            label="生日"
            name="birthdate"
          >
            <a-date-picker
              v-model:value="formState.birthdate"
              :disabled="loading"
              placeholder="請選擇日期"
              size="large"
              :style="{ minWidth: '100%' }"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="聯絡電話"
            name="phone"
            :rules="[{ required: true, message: '請輸入聯絡電話' }]"
          >
            <a-input
              v-model:value="formState.phone"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="地址"
            name="address"
          >
            <a-input
              v-model:value="formState.address"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider>合作社資料</a-divider>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="合作社名稱"
            name="coop_name"
          >
            <a-input v-model:value="formState.coop_name" readonly size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="合作社代號"
            name="coop_id"
          >
            <a-input v-model:value="formState.coop_id" readonly size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="社員編號"
            name="coop_user_id"
            :rules="[{ required: true, message: '請輸入社員編號' }]"
          >
            <a-input
              v-model:value="formState.coop_user_id"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, ref, reactive } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'

export default defineComponent({
  props: ['visible'],
  components: {},
  setup() {
    const store = useStore();

    const loading = ref(false);

    /* Data */
    const usersData = ref([]);

    /* Form */
    const initialState = {
      // user
      email: '',

      // user info
      last_name: '',
      first_name: '',
      national_id: '',
      gender: '',
      birthdate: '',
      phone: '',
      address: '',

      // coop info
      coop_name: store.state.auth.info.coop_info.name,
      coop_id: store.state.auth.info.coop_info.coop_id,
      coop_user_id: '',
    }

    const formState = reactive({ ...initialState });
    function resetForm() {
      loading.value = false;
      Object.assign(formState, initialState);
    }

    return {
      loading,

      /* Data */
      usersData,
      
      /* Form */
      formState,
      resetForm,

      /* Options */
      genderOptions: [{
        label: '先生',
        value: 'male'
      }, {
        label: '女士',
        value: 'female'
      }],

      message
    }
  },
  watch: {
    formState: {
      handler(newValue, _) {
        if (newValue.national_id.length === 10 && newValue.phone.length === 10) {
          this.formState.coop_user_id = this._coopUserIdGenerator(newValue.national_id, newValue.phone);
        } else {
          this.formState.coop_user_id = '';
        }
      },
      deep: true
    }
  },
  computed: {
    isPhone() {
      if (!this.formState.email.includes('@')) {
        return true
      }
      return false
    }
  },
  methods: {
    async handleOk() {
      await this.addUserInCoop();
    },
    handleCancel() {
      this.$emit('cancel');
      this.resetForm();
    },

    _checkRequired() {
      let err = '';

      if (this.formState.email == '') {
        err = '請輸入電子信箱';
      }
      else if (this.formState.last_name == '') {
        err = '請輸入姓氏';
      }
      else if (this.formState.first_name == '') {
        err = '請輸入名字';
      }
      else if (this.formState.national_id == '') {
        err = '請輸入身分證字號';
      }
      else if (this.formState.phone == '') {
        err = '請輸入聯絡電話';
      }
      else if (this.formState.coop_user_id == '') {
        err = '請輸入社員編號';
      }

      return err
    },

    /* Api */
    async addUserInCoop() {
      this.loading = true;

      let err = this._checkRequired();
      if (err) {
        this.message.error(err);
        this.loading = false;
        return
      }

      // 找到用戶 id
      const findUserUrl = process.env.VUE_APP_API + `/users?email=${this.formState.email}`;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.token}`
        }
      }
      const response = await axios.get(findUserUrl, config);
      if (response.data.length == 0) {
        message.error('找不到此使用者帳號');
        this.loading = false;
        return
      }

      const userData = response.data[0];
      console.log(userData)
      if (userData.coop_info != null) {
        if (userData.coop_info.id == this.$store.state.auth.info.coop_info.id) {
          this.loading = false;
          message.error('此使用者已經是本合作社的成員');
          return
        }
        if (userData.coop_info.id != this.$store.state.auth.info.coop_info.id) {
          this.loading = false;
          message.error('此使用者已經是其他合作社的成員');
          return
        }
      }

      // 更新 user_info
      await this.updateUserInfo(userData.user_info.id, undefined, {
        last_name: this.formState.last_name,
        first_name: this.formState.first_name,
        national_id: this.formState.national_id,
        gender: this.formState.gender,
        birthdate: this.formState.birthdate,
        phone: this.formState.phone,
        address: this.formState.address
      });

      // 加入合作社
      try {
        const url = process.env.VUE_APP_API + `/users/${userData.id}`;
        const params = {
          coop_info: this.$store.state.auth.info.coop_info.id
        }
        await axios.put(url, params, config);

        const coopUsersUrl = process.env.VUE_APP_API + `/coop-users`;
        const data = {
          user: userData.id,
          coop: this.$store.state.auth.info.coop_info.id,
          coop_user_id: this.formState.coop_user_id,
        }
        await axios.post(coopUsersUrl, data, config);
        message.success('加入合作社成功');
      } catch (error) {
        console.log(error);
        this.loading = false;
        message.error('加入合作社發生錯誤，請稍後再試');
      }

      this.resetForm();
      this.$emit('confirm');
    },
    _coopUserIdGenerator(national_id, phone) {
      // rule: 身份證字號首字母+身份證字號最後兩位+電話號碼最後4位
      const id = national_id.charAt(0) + national_id.substring(national_id.length - 2, national_id.length) + phone.substring(national_id.length - 4, national_id.length);
      return id
    },
    // NOTE: deprecated
    // _coopUserIdGenerator(coopId) {
    //   // prefix
    //   let prefix = '';
    //   if (coopId === 'tenmiles') prefix = 'A';

    //   // year
    //   const d = new Date();
    //   const year = d.getFullYear().toString().substr(-2, 2);

    //   // random number
    //   const randomValue = Date.now().toString().substr(-6, 6);

    //   // checksum
    //   let checksum = 0;
    //   for (const i in randomValue) {
    //     checksum += parseInt(randomValue[i]);
    //   }
    //   console.log(checksum)
    //   checksum = 9 - parseInt(checksum % 10);
    //   console.log(checksum)

    //   const id = `${prefix}${year}${randomValue}${checksum}`;
    //   return id
    // }
    
    async updateUserInfo(userInfoId, key=undefined, value) {
      let params = {};
      if (key == 'enc_k') {
        params = { enc_k: value }
      } else if (key == 'wallet_address') {
        params = { wallet_Address: value }
      } else if (key === undefined) {
        params = value;
      }

      let url = process.env.VUE_APP_API + `/user-infos/${userInfoId}`;
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.auth.token}`
          }
        }
        await axios.put(url, params, config);
      } catch (error) {
        console.log(error)
      }
    }
  }
})
</script>