<template>
<div class="app-members-page tenmiles-page">
  <div class="page__wrapper">
    <div class="page__header">
      <a-row>
        <a-col :span="12">
          <a-typography-title :level="2">社員帳號</a-typography-title>
        </a-col>
        <a-col :span="12" align="right">
          <a-button
            @click="addUserInCoopModal.visible=true"
            size="large"
            :style="{ marginRight: '12px' }"
          >新增社員</a-button>

          <a-button
            @click="createUserInCoopModal.visible=true"
            type="primary"
            size="large"
          >創建社員帳號</a-button>
        </a-col>
      </a-row>
    </div>

    <div class="page__body">
      <div class="body-item">
        <a-table
          :columns="columns"
          :data-source="memberAccounts"
          :loading="loading"
          rowKey="id"
          bordered
        >
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.key === 'name'">
              {{ record.user_info.last_name }}{{ record.user_info.first_name }}
            </template>

            <template v-if="column.key === 'coop_user_id'">
              {{ record.coop_user.coop_user_id }}
            </template>

            <template v-if="column.key === 'application_role'">
              <a-select
                v-model:value="record.applicationRoleList"
                :options="applicationRoleOptions"
                @change="handleUserApplicationRolesChange(record.id, record.applicationRoleList)"
                mode="multiple"
                style="min-width: 200px"
              />
            </template>

            <template v-if="column.key === 'action'">
              <!-- <a-button size="small" :style="{ marginRight: '8px' }" @click="viewMember(record)">查看</a-button> -->

              <!-- <a-button size="small" :style="{ marginRight: '8px' }" @click="openApplicationPermissionsModal(record)">應用程式權限</a-button> -->

              <a-dropdown>
                <template #overlay>
                  <a-menu>
                    <a-popconfirm
                      :title="`確認從 ${$store.state.auth.info.coop_info.name} 退出 ${record.user_info.last_name}${record.user_info.first_name}？`"
                      ok-text="確定"
                      cancel-text="取消"
                      danger
                      @confirm="removeUserFromCoop(record.id)"
                      @cancel="message.info('取消退出動作')"
                    >
                      <a-menu-item key="3" danger>從合作社退出</a-menu-item>
                    </a-popconfirm>
                  </a-menu>
                </template>
                <a-button size="small">
                  更多 <DownOutlined />
                </a-button>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </div>

  <AddUserInCoopModal
    :visible="addUserInCoopModal.visible"
    @confirm="handleAddUserInCoopConfirm"
    @cancel="addUserInCoopModal.visible = false"
  />

  <CreateUserInCoopModal
    :visible="createUserInCoopModal.visible"
    @confirm="handleCreateUserInoCoopConfirm"
    @cancel="createUserInCoopModal.visible = false"
  />

  <!-- <UserApplicationPermissionsModal
    :visible="userApplicationPermissionsModal.visible"
    :userData="userApplicationPermissionsModal.data"
    @cancel="handleUserApplicationPermissionsCancel"
    @update="getMemberAccounts"
  /> -->
</div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import { message } from 'ant-design-vue'
import { DownOutlined } from '@ant-design/icons-vue'
import AddUserInCoopModal from './components/AddUserInCoopModal.vue'
import CreateUserInCoopModal from './components/CreateUserInCoopModal.vue'
// import UserApplicationPermissionsModal from './components/UserApplicationPermissionsModal.vue'

const columns = [{
  title: '會員編號',
  dataIndex: 'coop_user_id',
  key: 'coop_user_id'
}, {
  title: '信箱',
  dataIndex: 'email',
  key: 'email'
}, {
  title: '姓名',
  dataIndex: 'name',
  key: 'name'
}, {
  title: '身份',
  key: 'application_role'
}, {
  title: '操作',
  key: 'action'
}]

export default defineComponent({
  components: {
    DownOutlined,
    AddUserInCoopModal,
    CreateUserInCoopModal,
    // UserApplicationPermissionsModal
  },
  setup() {
    const store = useStore();

    /* Data */
    const loading = ref(false);
    const memberAccounts = ref([]);
    const applicationRoleOptions = ref([]);

    /* Api */
    const getMemberAccounts = (async () => {
      loading.value = true;

      const url = process.env.VUE_APP_API + `/users?role=1&coop_info.id=${store.state.auth.info.coop_info.id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${store.state.auth.token}`
        }
      }
      const response = await axios.get(url, config);

      // 攤平 application_permissions 格式
      response.data.forEach(member => {
        member.applicationRoleList = member.application_roles.map(item => item.id);
      });

      console.log(response.data);
      memberAccounts.value = response.data;

      loading.value = false;
    });

    const getApplicationRoles = (async () => {
      const url = process.env.VUE_APP_API + '/application-roles';
      const response = await axios.get(url);
      console.log(response.data);

      const roles = [];
      response.data.forEach(item => {
        roles.push({
          label: item.name,
          value: item.id
        });
      });
      applicationRoleOptions.value = roles;
    });

    const handleUserApplicationRolesChange = (async (userId, applicationRoles) => {
      console.log(applicationRoles);

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`
          }
        }
        const url = process.env.VUE_APP_API + `/users/${userId}`;
        const params = {
          application_roles: applicationRoles
        }
        await axios.put(url, params, config);

        message.success(`更新身份成功`);
      } catch (error) {
        console.log(error);
        message.error(`更新身份發生錯誤，請稍後再試`);
      }
    });

    const removeUserFromCoop = (async(id) => {
      try {
        const url = process.env.VUE_APP_API + `/users/${id}`;
        const config = {
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`
          }
        }
        const params = {
          coop_info: null
        }
        await axios.put(url, params, config);

        // coop-users collection
        const searchCoopUsersUrl = process.env.VUE_APP_API + `/coop-users?user=${id}`;
        const response = await axios.get(searchCoopUsersUrl, config);

        const coopUsersUrl = process.env.VUE_APP_API + `/coop-users/${response.data[0].id}`;
        await axios.delete(coopUsersUrl, config);
        
        message.success('退出社員成功');
      } catch (error) {
        console.log(error);
        message.error('退出社員發生錯誤，請稍後再試');
      } finally {
        getMemberAccounts();
      }
    });

    /* Mounted */
    onMounted(async() => {
      await getMemberAccounts();
      await getApplicationRoles();
    });

    return {
      message,
      
      /* Data */
      columns,
      loading,
      memberAccounts,
      applicationRoleOptions,

      /* Api */
      getMemberAccounts,
      handleUserApplicationRolesChange,
      removeUserFromCoop
    }
  },
  data() {
    return {
      /* Add user in coop modal */
      addUserInCoopModal: {
        visible: false
      },

      /* Create user in coop modal */
      createUserInCoopModal: {
        visible: false
      },

      /* User Application Permissions Modal */
      // userApplicationPermissionsModal: {
      //   visible: false,
      //   data: {}
      // }
    }
  },
  methods: {
    /* View */
    viewMember(user) {
      console.log(user);
    },
    
    /* Add user in coop modal */
    handleAddUserInCoopConfirm() {
      this.addUserInCoopModal.visible = false;
      this.getMemberAccounts();
    },

    /* Create user in coop modal */
    handleCreateUserInoCoopConfirm() {
      this.createUserInCoopModal.visible = false;
      this.getMemberAccounts();

      this.message.success('創建社員帳號成功');
    },

    /* User Application Permissions Modal */
    // openApplicationPermissionsModal(data) {
    //   console.log(data);
    //   this.userApplicationPermissionsModal.data = data;
    //   this.userApplicationPermissionsModal.visible = true;
    // },
    // handleUserApplicationPermissionsCancel() {
    //   this.userApplicationPermissionsModal.visible = false;
    // }
  }
})
</script>